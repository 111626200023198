import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { fadeInOutAndHeightM3 } from '@ih/animations';
import { InfoPanelComponent } from '@ih/info-panel';
import { StripeConfig } from '@ih/interfaces';

@Component({
  selector: 'ih-stripe-status',
  standalone: true,
  imports: [InfoPanelComponent, MatButtonModule, RouterModule],
  templateUrl: './stripe-status.component.html',
  styleUrl: './stripe-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOutAndHeightM3]
})
export class StripeStatusComponent {
  stripeConfig = input<StripeConfig | null>(null);

  navigated = output();

  stripeStatus = computed(() => {
    if (!this.stripeConfig()) {
      return 'loading';
    }

    if (!this.stripeConfig()?.accountId) {
      return 'not-connected';
    }

    if (!this.stripeConfig()?.accountComplete) {
      return 'incomplete';
    }

    if (!this.stripeConfig()?.chargesEnabled) {
      return 'disabled';
    }

    return 'enabled';
  });
}
