import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, input, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { fadeInOutAndHeightM3 } from '@ih/animations';
import { CurrencyDirective } from '@ih/directives';
import { RecurringInterval, TaxBehavior } from '@ih/enums';
import { InfoPanelComponent } from '@ih/info-panel';
import { BaseClientConfig, Price, PriceForm } from '@ih/interfaces';
import { ConfigService } from '@ih/services';
import { ngValueAccessorProvider } from '@ih/utilities';
import { debounceTime, map, noop, take } from 'rxjs';

@Component({
  selector: 'ih-price-editor',
  standalone: true,
  imports: [
    CurrencyPipe,

    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,

    CurrencyDirective,
    InfoPanelComponent
  ],
  templateUrl: './price-editor.component.html',
  styleUrl: './price-editor.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ngValueAccessorProvider(() => PriceEditorComponent)],
  animations: [fadeInOutAndHeightM3]
})
export class PriceEditorComponent implements OnInit, ControlValueAccessor {
  private config = inject(ConfigService<BaseClientConfig>);
  private destroyRef = inject(DestroyRef);

  private onChanged: (modelValue?: Price) => void = noop;
  private onTouched: () => void = noop;

  RecurringInterval = RecurringInterval;
  TaxBehavior = TaxBehavior;

  intervals = [
    { value: RecurringInterval.Day, label: 'Daily' },
    { value: RecurringInterval.Week, label: 'Weekly' },
    { value: RecurringInterval.Month, label: 'Monthly' },
    { value: RecurringInterval.Year, label: 'Yearly' }
  ];

  parentForm = input<FormGroup<PriceForm> | null>(null);

  showMinimumPriceWarning = signal(false);

  hasStripeTaxesEnabled = toSignal(this.config.config$.pipe(map((config) => config.stripe.taxesEnabled)));
  stripeAccountId = toSignal(this.config.config$.pipe(map((config) => config.stripe.accountId)));

  ngOnInit() {
    this.config.config$.pipe(take(1)).subscribe((config) => {
      this.parentForm()!.controls.currency.setValue(config.currency, { emitEvent: false });
    });

    this.parentForm()!
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.onChanged(value as Price);
      });

    this.parentForm()!
      .controls['interval'].valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((interval) => {
        if (interval === RecurringInterval.Never) {
          this.parentForm()!.controls['intervalCount'].setValue(null, { emitEvent: false });
        }
      });

    this.parentForm()!
      .controls['amount'].valueChanges.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
      .subscribe((price) => {
        this.showMinimumPriceWarning.set(!price || price < 3);
      });
  }

  writeValue(obj: Price): void {
    if (obj) {
      this.config.config$.pipe(take(1)).subscribe((config) => {
        this.parentForm()!.setValue(
          {
            amount: obj.amount,
            currency: obj.currency ?? config.currency,
            interval: obj.interval ?? RecurringInterval.Never,
            intervalCount: obj.intervalCount,
            taxBehavior: obj.taxBehavior ?? TaxBehavior.Exclusive,
            archived: obj.archived ?? false
          },
          { emitEvent: false }
        );
      });
    }
  }
  registerOnChange(fn: (modelValue?: Price) => void): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {}

  setRecurring(recurring: boolean) {
    this.parentForm()!.controls['interval'].setValue(recurring ? RecurringInterval.Month : RecurringInterval.Never);
  }

  setTaxBehavior(behavior: TaxBehavior) {
    this.parentForm()!.controls['taxBehavior'].setValue(behavior);
  }

  compareInterval(a: RecurringInterval, b: RecurringInterval): boolean {
    return a === b;
  }
}
