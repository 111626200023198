@switch (stripeStatus()) {
  @case ('not-connected') {
    <ih-info-panel
      @fadeInOutAndHeightM3
      class="stripe-not-connected-warning"
      type="error"
      [dismissable]="false"
      [showIcon]="false"
    >
      <span title>Action needed: Connect your stripe account</span>
      <div>
        <p>
          To use paid channels and start processing payments, you need to connect your Stripe account. This is a quick
          process that will allow you to seamlessly manage transactions through our platform.
        </p>
        <p>Don't worry, we'll guide you through the steps to get set up.</p>
        <p>
          If you don't complete this process, you will be unable to use paid channels, or process payments through our
          platform
        </p>
      </div>
      <div class="stripe-incomplete-warning-actions fx-layout-row fx-layout-align-end fx-gap-8">
        <a mat-flat-button class="contact-support-btn" type="button" target="_blank" href="https://support.ihub.app/"
          >Get help</a
        >
        <a
          mat-flat-button
          class="stripe-connect-btn"
          type="button"
          (click)="navigated.emit()"
          [routerLink]="['/admin/merchantAccounts']"
        >
          Connect Stripe
        </a>
      </div>
    </ih-info-panel>
  }
  @case ('incomplete') {
    <ih-info-panel
      @fadeInOutAndHeightM3
      class="stripe-incomplete-warning"
      type="warn"
      [dismissable]="false"
      [showIcon]="false"
    >
      <span title>Action required: Complete your stripe account setup</span>
      <div>
        <p>
          You've started the process of connecting your Stripe account, but it looks like there are still some steps
          left to complete.
        </p>
        <p>
          To use paid channels and start processing payments, please proceed to the payment processor page and finish
          the account setup.
        </p>
        <p>If you don't complete this process, you won't be able to accept payments through our platform.</p>
      </div>
      <div class="stripe-incomplete-warning-actions fx-layout-row fx-layout-align-end fx-gap-8">
        <a mat-flat-button class="contact-support-btn" type="button" target="_blank" href="https://support.ihub.app/"
          >Get help</a
        >
        <a
          mat-flat-button
          class="stripe-connect-btn"
          type="button"
          (click)="navigated.emit()"
          [routerLink]="['/admin/merchantAccounts']"
        >
          Complete setup
        </a>
      </div>
    </ih-info-panel>
  }
  @case ('disabled') {
    <ih-info-panel
      @fadeInOutAndHeightM3
      class="stripe-incomplete-warning"
      type="warn"
      [dismissable]="false"
      [showIcon]="false"
    >
      <span title>Important notice: Account action required</span>
      <div>
        <p>
          It looks like your Stripe account is currently unable to process charges. This is because a required action or
          update wasn't completed by the deadline set by Stripe.
        </p>
        <p>
          To restore your account's functionality, please proceed to the payment processors page and update your account
          with the required information.
        </p>

        <p>If you have any questions or need assistance, feel free to contact our support team for help.</p>
      </div>
      <div class="stripe-incomplete-warning-actions fx-layout-row fx-layout-align-end fx-gap-8">
        <a mat-flat-button class="contact-support-btn" type="button" target="_blank" href="https://support.ihub.app/"
          >Get help</a
        >
        <a
          mat-flat-button
          class="stripe-connect-btn"
          type="button"
          (click)="navigated.emit()"
          [routerLink]="['/admin/merchantAccounts']"
        >
          Complete account
        </a>
      </div>
    </ih-info-panel>
  }
}
