import { Directive, ElementRef, HostListener, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { Keys } from '@ih/enums';

@Directive({
  selector: '[ihCurrency]',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencyDirective),
      multi: true
    }
  ]
})
export class CurrencyDirective implements ControlValueAccessor, OnInit {
  @Input('ihCurrency') currencyCode: string = 'USD';
  private el: HTMLInputElement;
  private currencySymbol: string = '';
  private onChange: (value: number | null) => void = () => {};
  private onTouched: () => void = () => {};
  private matInput: MatInput | null = null;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.currencySymbol = this.getCurrencySymbol();
  }

  ngAfterViewInit() {
    // Check if the directive is applied to a matInput
    this.matInput = (this.el as any)._elementRef?.nativeElement?.__ngContext__?.find(
      (node: any) => node instanceof MatInput
    );

    if (this.matInput) {
      this.matInput.type = 'tel';
      // Set the placeholder for matInput
      this.matInput.placeholder = this.formatCurrency(1);
    } else {
      this.el.type = 'tel';
      // Set the placeholder for regular input
      this.el.placeholder = this.formatCurrency(1);
    }
  }

  @HostListener('focus')
  onFocus(): void {
    if (this.el.value === '') {
      this.el.value = this.currencySymbol;
      // move the cursor to the end of the input
      this.el.setSelectionRange(-1, -1);
    } else {
      this.el.setSelectionRange(this.currencySymbol.length, this.el.value.length);
    }
  }

  @HostListener('blur')
  onBlur(): void {
    this.onTouched();
    this.formatValue();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;
    const selectionStart = this.el.selectionStart!;
    const selectionEnd = this.el.selectionEnd;
    const decimalIndex = this.el.value.indexOf('.');

    // Allow navigation keys
    if (['ArrowLeft', 'ArrowRight', 'Tab', 'End', 'Home'].includes(event.key) || event.ctrlKey) {
      return;
    }

    // if the key is period, then move the cursor to the cents part
    if (event.key === '.' && decimalIndex !== -1 && selectionStart <= decimalIndex) {
      event.preventDefault();
      input.setSelectionRange(decimalIndex + 1, decimalIndex + 1);
      return;
    }

    // Prevent non-numeric input
    if (!/^\d$/.test(event.key) && event.key !== Keys.Backspace && event.key !== Keys.Delete) {
      event.preventDefault();
      return;
    }

    // check if the key is backspace
    if (event.key === Keys.Backspace) {
      // if the user is deleting a comma or a period, prevent the default behavior and delete the digit instead
      if (decimalIndex !== -1 && selectionStart === decimalIndex + 1) {
        event.preventDefault();

        // move the cursor to the left
        input.setSelectionRange(selectionStart - 1, selectionStart - 1);
        return;
      }
      // check if the user is deleting a digit after the decimal point
      else if (decimalIndex !== -1 && selectionStart > decimalIndex) {
        event.preventDefault();
        // replace the digit with a zero
        value = value.slice(0, selectionStart - 1) + '0' + value.slice(selectionStart);
        // Update the input value
        this.el.value = value;

        const numericValue = this.parseValue(value);
        this.onChange(numericValue);

        // if this is the first digit after the decimal point, move the cursor to the left
        if (selectionStart === decimalIndex + 1) {
          input.setSelectionRange(selectionStart - 2, selectionStart - 2);
          return;
        }
        // Set the cursor position
        input.setSelectionRange(selectionStart - 1, selectionStart - 1);
        return;
      }
      // if the cursor is after a comma, prevent the default behavior and delete the digit instead
      else if (value[selectionStart - 1] === ',' && selectionStart === selectionEnd) {
        event.preventDefault();
        // remove the digit before the comma
        value = value.slice(0, selectionStart - 2) + value.slice(selectionStart - 1);

        // Update the input value
        const formattedValue = this.formatWhileTyping(value);
        const numericValue = this.parseValue(formattedValue);

        // Update the input value
        this.el.value = formattedValue;
        this.onChange(numericValue);

        // Set the cursor position
        input.setSelectionRange(selectionStart - 1, selectionStart - 1);
      } else {
        // otherwise, allow the default behavior
        return;
      }
    }

    // check if the key is delete
    if (event.key === Keys.Delete) {
      // if the user is deleting a comma or a period
      if (decimalIndex !== -1 && selectionStart === decimalIndex) {
        //prevent the default behavior and delete the digit instead
        event.preventDefault();
      }
      // check if the user is deleting a digit after the decimal point
      else if (decimalIndex !== -1 && selectionStart >= decimalIndex) {
        event.preventDefault();
        // replace the digit with a zero
        value = value.slice(0, selectionStart) + '0' + value.slice(selectionStart + 1);
        // Update the input value
        this.el.value = value;

        const numericValue = this.parseValue(value);
        this.onChange(numericValue);

        // Set the cursor position
        input.setSelectionRange(selectionStart, selectionStart);
        return;
      }
      // if the cursor is after a comma, prevent the default behavior and delete the digit instead
      else if (value[selectionStart] === ',' && selectionStart === selectionEnd) {
        event.preventDefault();
        // remove the digit after the comma
        value = value.slice(0, selectionStart + 1) + value.slice(selectionStart + 2);

        // Update the input value
        const formattedValue = this.formatWhileTyping(value);
        const numericValue = this.parseValue(formattedValue);

        // Update the input value
        this.el.value = formattedValue;
        this.onChange(numericValue);

        // Set the cursor position
        input.setSelectionRange(selectionStart, selectionStart);
      } else {
        // otherwise, allow the default behavior
        return;
      }
    }

    if (selectionStart !== null && selectionEnd !== null) {
      const decimalIndex = value.indexOf('.');
      if (decimalIndex !== -1 && selectionStart > decimalIndex) {
        // We're in the cents part
        event.preventDefault();

        let newValue = value;
        if (selectionStart === value.length) {
          // push the new digit to the end of the string
          newValue = value + event.key;
          // shift the decimal point to the right
          newValue = newValue.replace('.', '');
          newValue = newValue.slice(0, selectionStart - 2) + '.' + newValue.slice(selectionStart - 2);
        } else {
          // insert the new digit at the cursor position
          newValue = value.slice(0, selectionStart) + event.key + value.slice(selectionStart + 1);
        }

        const formattedValue = this.formatWhileTyping(newValue);
        // if a comma was added by the formatting function, the cursor should be moved to the right
        const cursorOffset = formattedValue.length - value.length;
        let newSelectionStart = selectionStart + cursorOffset;
        // if the cursorOffset is 0, the cursor should be moved to the right
        if (cursorOffset === 0) {
          newSelectionStart++;
        }

        const numericValue = this.parseValue(formattedValue);

        // Update the input value
        this.el.value = formattedValue;
        this.onChange(numericValue);

        // Set the cursor position
        setTimeout(() => {
          input.setSelectionRange(newSelectionStart, newSelectionStart);
        });

        // Update matInput value if it exists
        if (this.matInput) {
          this.matInput.value = formattedValue;
        }
      }
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;
    let selectionStart = input.selectionStart;
    const selectionEnd = input.selectionEnd;

    if (selectionStart !== null && selectionEnd !== null) {
      const decimalIndex = value.indexOf('.');
      if (decimalIndex !== -1 && selectionStart > decimalIndex) {
        // We're in the cents part
        const newDigit = value[selectionStart - 1];
        // remove the new digit
        value = value.slice(0, selectionStart - 1) + value.slice(selectionStart);
        // update selection start to account for the removed digit
        selectionStart--;

        // check if the cursor is at the end of the input
        if (selectionStart === value.length) {
          // replace the last digit
          value = value.slice(0, selectionStart - 1) + newDigit;
        } else {
          // insert the new digit at the cursor position
          value = value.slice(0, selectionStart) + newDigit + value.slice(selectionStart + 1);
          // update selection start to account for the inserted digit
          selectionStart++;
        }
      }
    }

    const formattedValue = this.formatWhileTyping(value);
    const numericValue = this.parseValue(formattedValue);

    // Update the input value
    this.el.value = formattedValue;
    this.onChange(numericValue);

    if (selectionStart !== null) {
      // if cents was not present and was added, the cursor should be placed before the decimal point
      if (value.indexOf('.') === -1 && formattedValue.indexOf('.') !== -1) {
        // do not modify the cursor position
      } else {
        // if the formatted value is longer than the input value, the cursor position will be off by the difference in length
        // we need to adjust the cursor position to account for this
        const lengthDifference = formattedValue.length - value.length;
        selectionStart += lengthDifference;
      }
    }

    // restore the cursor position
    input.setSelectionRange(selectionStart, selectionStart);

    // Update matInput value if it exists
    if (this.matInput) {
      this.matInput.value = formattedValue;
    }
  }

  writeValue(value: number | null): void {
    if (value !== null) {
      this.el.value = this.formatCurrency(value);
    } else {
      this.el.value = '';
    }
  }

  registerOnChange(fn: (value: number | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.el.disabled = isDisabled;
  }

  private formatValue(): void {
    const numericValue = this.parseValue(this.el.value);
    if (numericValue !== null) {
      this.el.value = this.formatCurrency(numericValue);
    } else {
      this.el.value = '';
    }

    // Update matInput value if it exists
    if (this.matInput) {
      this.matInput.value = this.el.value;
    }
  }

  private formatWhileTyping(value: string): string {
    // Remove all non-digit characters except for the decimal point
    const digitsOnly = value.replace(/[^\d.]/g, '');

    // Parse the numeric value
    const numericValue = parseFloat(digitsOnly);

    if (isNaN(numericValue)) {
      return this.currencySymbol;
    }

    // Format the number with commas for thousands
    const parts = numericValue.toFixed(2).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Combine the formatted parts
    return this.currencySymbol + parts.join('.');
  }

  private parseValue(value: string): number | null {
    const numericString = value.replace(this.currencySymbol, '').replace(/[^0-9.-]+/g, '');
    const numericValue = parseFloat(numericString);
    return isNaN(numericValue) ? null : numericValue;
  }

  private formatCurrency(value: number): string {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: this.currencyCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  }

  private getCurrencySymbol(): string {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: this.currencyCode
    });
    const parts = formatter.formatToParts(0);
    const symbol = parts.find((part) => part.type === 'currency')?.value || '$';
    return symbol;
  }
}
