<div [formGroup]="parentForm()!">
  <div role="group" class="recurring-toggle-container fx-layout fx-layout-align-start-center">
    <button
      class="recurring-toggle-button fx-flex"
      mat-stroked-button
      [class.active]="parentForm()!.value.interval !== RecurringInterval.Never"
      type="button"
      (click)="setRecurring(true)"
    >
      Recurring
    </button>
    <button
      class="recurring-toggle-button fx-flex"
      mat-stroked-button
      [class.active]="parentForm()!.value.interval === RecurringInterval.Never"
      type="button"
      (click)="setRecurring(false)"
    >
      One-time
    </button>
  </div>
  <div class="amount-input-container">
    <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
      <mat-label>Amount</mat-label>
      <input
        class="price-input"
        #priceInput
        [ihCurrency]="this.parentForm()!.value.currency?.code?.toUpperCase() ?? 'USD'"
        matInput
        formControlName="amount"
      />
      <span matTextSuffix>{{ this.parentForm()!.value.currency?.code?.toUpperCase() }}</span>
      @if (parentForm()!.value.interval !== RecurringInterval.Never) {
        <mat-hint>Amount per interval</mat-hint>
      } @else {
        <mat-hint>One-time amount</mat-hint>
      }
      @if (parentForm()!.controls['amount'].hasError('required')) {
        <mat-error>Amount is required</mat-error>
      }
      @if (parentForm()!.controls['amount'].hasError('max')) {
        <mat-error
          >Amount must be less than
          {{ '100000' | currency: this.parentForm()!.value.currency?.code?.toUpperCase() ?? 'USD' }}</mat-error
        >
      }
    </mat-form-field>
  </div>
  @if (showMinimumPriceWarning()) {
    <ih-info-panel
      @fadeInOutAndHeightM3
      class="minimum-price"
      [showIcon]="false"
      [type]="parentForm()!.controls['amount'].hasError('min') ? 'error' : 'warn'"
      dismissable="false"
    >
      <span
        >Tip: The minimum price is
        {{ '1' | currency: this.parentForm()!.value.currency?.code?.toUpperCase() ?? 'USD' }}, but we recommend setting
        it to at least {{ '3' | currency: this.parentForm()!.value.currency?.code?.toUpperCase() ?? 'USD' }} to ensure
        fees don’t consume your earnings.
      </span>
    </ih-info-panel>
  }
  @if (parentForm()!.value.interval !== RecurringInterval.Never) {
    <div class="interval-container">
      <mat-form-field class="interval-form-field" appearance="outline" floatLabel="always" subscriptSizing="dynamic">
        <mat-label>Interval</mat-label>
        <mat-select formControlName="interval" [compareWith]="compareInterval">
          @for (interval of intervals; track interval.value) {
            <mat-option [value]="interval.value">{{ interval.label }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  }
  @if (!hasStripeTaxesEnabled()) {
    <ih-info-panel class="stripe-tax-not-enabled-warning" type="warn">
      <div title>Stripe tax not enabled</div>
      <div>
        Stripe tax is not enabled. If you want to collect taxes, you need to enable it in your Stripe account settings.
      </div>
      <a
        mat-flat-button
        class="btn-connect complete-account-setup-btn"
        target="_blank"
        href="https://dashboard.stripe.com/b/{{ stripeAccountId() }}?=destination=/settings/tax"
      >
        Stripe tax settings
        <mat-icon iconPositionEnd svgIcon="open-in-new"></mat-icon>
      </a>
    </ih-info-panel>
  } @else {
    <div role="group" class="taxes-toggle-container fx-layout fx-layout-align-start-center">
      <button
        class="taxes-toggle-button fx-flex"
        mat-stroked-button
        [class.active]="parentForm()!.value.taxBehavior === TaxBehavior.Exclusive"
        type="button"
        (click)="setTaxBehavior(TaxBehavior.Exclusive)"
      >
        Taxes excluded
      </button>
      <button
        class="taxes-toggle-button fx-flex"
        mat-stroked-button
        [class.active]="parentForm()!.value.taxBehavior === TaxBehavior.Inclusive"
        type="button"
        (click)="setTaxBehavior(TaxBehavior.Inclusive)"
      >
        Taxes included
      </button>
    </div>
  }
</div>
